<template>
  <div>
    <div class="top_select">
      <div>
        <el-radio-group v-model="type" size="small" @change="init">
          <el-radio-button label="1">业务类型</el-radio-button>
          <el-radio-button label="2">客户来源</el-radio-button>
          <el-radio-button label="3">收款方式</el-radio-button>
        </el-radio-group>
      </div>
      <div>
        <el-button
          :disabled="!$buttonStatus('ywlx_xzywlx')"
          type="primary"
          size="small"
          @click="addCategoryType()"
          icon="Plus"
          plain
          v-if="type == '1'"
          >新增业务类型</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="addSourceData()"
          icon="Plus"
          plain
          v-if="type == '2'"
          >新增客户来源</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="addMethodData()"
          icon="Plus"
          plain
          v-if="type == '3'"
          >新增收款方式</el-button
        >
      </div>
    </div>
    <div v-if="type == '1'">
      <el-table
        :data="categoryList"
        style="width: 100%"
        border
        :height="contentStyleObj"
        default-expand-all
      >
        <template #empty>
          <el-empty :image-size="100" description="没有数据"></el-empty>
        </template>
        <el-table-column type="expand">
          <template #default="props">
            <el-table :data="props.row.children" :show-header="false">
              <el-table-column label="业务类型" prop="categoryName">
                <template #default="scope">
                  <div style="margin-left:60px">
                    {{ scope.row.categoryName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template #default="scope">
                  <el-button
                    :disabled="
                      !$buttonStatus('ywlx_xg') ||
                        scope.row.id == 1 ||
                        scope.row.id == 2 ||
                        scope.row.id == 3 ||
                        scope.row.id == 4 ||
                        scope.row.id == 5
                    "
                    type="text"
                    plain
                    @click="editName(scope.row)"
                    size="small"
                    icon="EditPen"
                    style="margin-left:30px"
                    >修改</el-button
                  >
                  <el-button
                    :disabled="
                      !$buttonStatus('ywlx_sc') ||
                        scope.row.id == 1 ||
                        scope.row.id == 2 ||
                        scope.row.id == 3 ||
                        scope.row.id == 4 ||
                        scope.row.id == 5
                    "
                    type="text"
                    size="small"
                    @click="delCategoryName(scope.row)"
                    v-show="props.row.children.length > 1"
                    icon="Delete"
                    style="color: #f56c6c"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column label="业务类型">
          <template #default="scope">
            <span>{{ scope.row.categoryName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button
              :disabled="!$buttonStatus('ywlx_xzywlx')"
              type="text"
              size="small"
              @click="addName(scope.row)"
              icon="Star"
              >新增服务项目</el-button
            >
            <el-button
              :disabled="
                !$buttonStatus('ywlx_xg') ||
                  scope.row.id == 1 ||
                  scope.row.id == 2
              "
              type="text"
              @click="editType(scope.row)"
              size="small"
              icon="Edit"
              >修改</el-button
            >
            <el-button
              :disabled="
                !$buttonStatus('ywlx_sc') ||
                  scope.row.id == 1 ||
                  scope.row.id == 2
              "
              type="text"
              size="small"
              @click="delCategoryType(scope.row)"
              icon="Delete"
              style="color: #f56c6c"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-if="type == '2'">
      <el-table
        :data="sourceTableData"
        style="width: 100%"
        border
        :height="contentStyleObj"
      >
        <template #empty>
          <el-empty :image-size="100" description="没有数据"></el-empty>
        </template>
        <el-table-column label="序号" align="center" width="55" type="index">
        </el-table-column>
        <el-table-column label="来源" prop="sourceName" />
        <el-table-column label="创建时间" prop="createdAt">
          <template #default="scope">
            <div>
              {{ $parseTime(scope.row.createdAt, "{y}-{m}-{d} {h}:{i}:{s}") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button
              type="text"
              size="small"
              icon="Edit"
              :disabled="
                scope.row.id == 1 || scope.row.id == 2 || scope.row.id == 3
              "
              @click="editSource(scope.row)"
              >修改</el-button
            >
            <el-button
              type="text"
              size="small"
              icon="Delete"
              @click="delSourceData(scope.row)"
              style="color: #f56c6c"
              :disabled="
                scope.row.id == 1 || scope.row.id == 2 || scope.row.id == 3
              "
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-if="type == '3'">
      <el-table
        :data="methodTableData"
        style="width: 100%"
        border
        :height="contentStyleObj"
      >
        <template #empty>
          <el-empty :image-size="100" description="没有数据"></el-empty>
        </template>
        <el-table-column label="序号" align="center" width="55" type="index">
        </el-table-column>
        <el-table-column label="收款方式" prop="methodName" />
        <el-table-column label="创建时间" prop="createdAt">
          <template #default="scope">
            <div>
              {{ $parseTime(scope.row.createdAt, "{y}-{m}-{d} {h}:{i}:{s}") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button
              type="text"
              plain
              size="small"
              icon="Edit"
              :disabled="
                scope.row.id == 1 ||
                  scope.row.id == 2 ||
                  scope.row.id == 3 ||
                  scope.row.id == 4
              "
              @click="editMethod(scope.row)"
              >修改</el-button
            >
            <el-button
              type="text"
              size="small"
              icon="Delete"
              @click="delMethodData(scope.row)"
              :disabled="
                scope.row.id == 1 ||
                  scope.row.id == 2 ||
                  scope.row.id == 3 ||
                  scope.row.id == 4
              "
              style="color: #f56c6c"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
  <el-dialog
    :close-on-click-modal="false"
    v-model="addCategoryDialog"
    :title="textMap[dialogStatus]"
    width="500px"
  >
    <el-form
      :model="categoryForm"
      ref="categoryFormRef"
      :rules="rules"
      status-icon
      size="small"
    >
      <el-form-item
        label="业务类型："
        prop="categoryType"
        v-show="dialogStatus == 'addType'"
      >
        <el-input v-model="categoryForm.categoryType" />
      </el-form-item>
      <el-form-item label="服务项目：" prop="categoryName">
        <el-input v-model="categoryForm.categoryName" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="addCategoryDialog = false" size="small"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="dialogStatus === 'addType' ? createData() : updateNameData()"
        >
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    :close-on-click-modal="false"
    v-model="editTypeDialog"
    title="修改业务类型"
    width="500px"
  >
    <el-form :model="form" ref="formRef" :rules="rules" status-icon>
      <el-form-item label="业务类型：">
        <el-input v-model="form.categoryName" size="small" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="editTypeDialog = false" size="small">取消</el-button>
        <el-button type="primary" @click="updateType" size="small">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    :close-on-click-modal="false"
    v-model="editNameDialog"
    title="修改服务项目"
    width="500px"
  >
    <el-form :model="nameForm" ref="nameFormRef" :rules="rules" status-icon>
      <el-form-item label="服务项目：">
        <el-input v-model="nameForm.categoryName" placeholder="请输入服务项目" size="small" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="editNameDialog = false" size="small">取消</el-button>
        <el-button type="primary" @click="updateName" size="small">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>

  <addSource ref="addSource" @success="getSource" />
  <addMethod ref="addMethod" @success="getMethod" />
</template>

<script>
import {
  getCategoryList,
  addCategory,
  addCategoryName,
  delCategoryName,
  delCategory,
  sourceList,
  deleteSource,
  paymentMethodList,
  deleteMethod,
  categoryListNew,
} from "@/api/crm";
import addSource from "../components/addSource.vue";
import addMethod from "../components/addMethod.vue";
export default {
  name: "businessType",
  components: {
    addSource,
    addMethod,
  },
  data() {
    return {
      textMap: {
        addName: "新增服务项目",
        addType: "新增业务类型",
      },
      categoryForm: {
        categoryType: "",
        categoryName: "",
        typeId: null,
      },
      categoryList: [],
      addCategoryDialog: false,
      rules: {
        categoryType: [
          {
            required: true,
            message: "请输入业务类型名称",
            trigger: "blur",
          },
        ],
        categoryName: [
          {
            required: true,
            message: "请输入服务项目",
            trigger: "blur",
          },
        ],
      },
      dialogStatus: "",
      form: {},
      editTypeDialog: false,
      editNameDialog: false,
      nameForm: {},
      sourceTableData: [],
      methodTableData: [],
      contentStyleObj: {},
      type: "1",
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(210);
    this.init();
  },
  methods: {
    init() {
      if (this.type == "1") {
        this.getList();
      } else if (this.type == "2") {
        this.getSource();
      } else if (this.type == "3") {
        this.getMethod();
      }
    },
    getList() {
      categoryListNew({})
        .then((res) => {
          if (res.data.msg == "success") {
            this.categoryList = res.data.data.list ? res.data.data.list : [];
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    //来源
    getSource() {
      sourceList({}).then((res) => {
        if (res.data.msg == "success") {
          this.sourceTableData = res.data.data.list ? res.data.data.list : [];
        }
      });
    },
    //方式
    getMethod() {
      paymentMethodList({}).then((res) => {
        if (res.data.msg == "success") {
          this.methodTableData = res.data.data.list ? res.data.data.list : [];
        }
      });
    },
    //新增业务类型
    addCategoryType() {
      this.resetForm();
      this.dialogStatus = "addType";
      this.addCategoryDialog = true;
      this.$nextTick(() => {
        this.$refs["categoryFormRef"].clearValidate();
      });
    },
    //新增服务项目
    addName(row) {
      this.resetForm();
      this.categoryForm.typeId = row.id;
      this.categoryForm.categoryName = "";
      this.dialogStatus = "addName";
      this.addCategoryDialog = true;
      this.$nextTick(() => {
        this.$refs["categoryFormRef"].clearValidate();
      });
    },
    createData() {
      this.$refs["categoryFormRef"].validate((valid) => {
        if (valid) {
          addCategory(this.categoryForm)
            .then((res) => {
              if (res.data.msg == "success") {
                this.$qzfMessage("新建成功");
                this.addCategoryDialog = false;
                this.getList();
                this.$bus.emit("businessTypeUpdate");
                this.$emit('success')
              }
            })
            .catch((err) => {
              //console.log(err);
            });
        }
      });
    },
    updateNameData() {
      if (!this.categoryForm.categoryName) {
        this.$qzfMessage("请输入服务项目", 1);
        return;
      }
      addCategoryName(this.categoryForm)
        .then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("新建成功");
            this.addCategoryDialog = false;
            this.getList();
            this.$bus.emit("businessTypeUpdate");
            this.$emit('success')
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    resetForm() {
      this.categoryForm = {
        categoryName: "",
        categoryType: "",
        typeId: null,
      };
    },
    //修改业务类型
    editType(row) {
      this.form = Object.assign({}, row);
      this.editTypeDialog = true;
    },
    updateType() {
      if (!this.form.categoryName) {
        this.$qzfMessage("请输入业务类型", 1);
        return;
      }
      addCategory({
        id: this.form.id,
        categoryType: this.form.categoryName,
      }).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("修改成功");
          this.$bus.emit("businessTypeUpdate");
          this.$bus.emit("contractUpdate");
          this.$bus.emit("contractUncheck");
          this.$emit('success')
          this.editTypeDialog = false;
          this.getList();
        }
      });
    },
    //修改服务项目
    editName(row) {
      this.nameForm = Object.assign({}, row);
      this.editNameDialog = true;
    },
    updateName() {
      if (!this.nameForm.categoryName) {
        this.$qzfMessage("请输入服务项目", 1);
        return;
      }
      addCategoryName(this.nameForm).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("修改成功");
          this.$bus.emit("businessTypeUpdate");
          this.$bus.emit("contractUpdate");
          this.$bus.emit("contractUncheck");
          this.$emit('success')
          this.editNameDialog = false;
          this.getList();
        }
      });
    },
    //删除服务项目
    delCategoryName(row) {
      this.$confirm("确认删除" + row.categoryName + "吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delCategoryName({
            id: row.id,
          }).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
              this.$bus.emit("businessTypeUpdate");
              this.$bus.emit("contractUpdate");
              this.$bus.emit("contractUncheck");
              this.$emit('success')
            }
          });
        })
        .catch(() => {});
    },
    //删除业务类型
    delCategoryType(row) {
      this.$confirm("确认删除" + row.categoryName + "吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delCategory({
            id: row.id,
          }).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
              this.$bus.emit("businessTypeUpdate");
              this.$bus.emit("contractUpdate");
              this.$bus.emit("contractUncheck");
              this.$emit('success')
            }
          });
        })
        .catch(() => {});
    },
    //新增来源
    addSourceData() {
      this.$refs.addSource.init();
    },
    editSource(row) {
      this.$refs.addSource.init(row);
    },
    //新增收款方式
    addMethodData() {
      this.$refs.addMethod.init();
    },
    editMethod(row) {
      this.$refs.addMethod.init(row);
    },
    //删除来源
    delSourceData(row) {
      this.$confirm("确认删除" + row.sourceName + "吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteSource({
            id: row.id,
          }).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getSource();
            }
          });
        })
        .catch(() => {});
    },
    //删除方式
    delMethodData(row) {
      this.$confirm("确认删除" + row.methodName + "吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteMethod({
            id: row.id,
          }).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getMethod();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 22px !important;
}

.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.item-p {
  border-top: 1px solid #efefef;
}
.item-p:nth-child(1) {
  border-top: none;
}
</style>
