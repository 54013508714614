<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="客户来源"
    width="300px"
  >
    <el-form :model="temp" ref="nameFormRef" status-icon>
      <el-form-item label="来源：">
        <el-input v-model="temp.sourceName" size="small" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small"
          >取消</el-button
        >
        <el-button type="primary" @click="createData" size="small">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { saveSource } from "@/api/crm";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      temp: {
        sourceName: "",
      },
    };
  },
  methods: {
    init(row) {
      if (row) {
        this.temp = Object.assign({}, row);
      }
      this.dialogFormVisible = true;
    },
    createData() {
      if (!this.temp.sourceName) {
        this.$qzfMessage("请输入来源", 1);
        return;
      }
      saveSource(this.temp).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("保存成功");
          this.dialogFormVisible = false;
          this.$emit("success", res.data.data.info);
        }
      });
    },
  },
};
</script>

<style scoped lang="less"></style>
